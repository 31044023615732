import { Dayjs } from 'dayjs';
import { GetCampaignResponse } from './campaignCreator';

export interface DropdownOption {
  displayName: string;
  displayNameFiltered?: string;
  enabled: boolean;
}
export interface DropdownOptions {
  [key: string]: DropdownOption;
}

export type RelativeDate = 'anytime' | 'last_24_hours' | 'last_week' | 'last_month' | 'custom';
export type GroupBy = 'none' | 'last_heard';

export type AudienceScoreMap = Record<
  string,
  {
    min: number;
    max: number;
    sum: number;
  }
>;

export interface Audience {
  advertiser_id: string;
  polygon_ids: string[];
  top_score: number;
  n_within_sel: number;
  categories: string[];
}

export interface AudienceListPolygonInfo {
  name: string;
  polygon_id: string;
  entity_count: number;
  selected: boolean;
  collapsed: boolean;
}

export interface AvailableAudienceByPolygon {
  data?: Audience[];
  isActive: boolean;
  hideEmpty?: boolean;
}

export interface AudienceGroupLastHeard {
  last_heard: number;
  advertisers: string[];
  num_polygons: string;
}

export interface AudienceRequest {
  polygon_ids?: number[] | string[];
  audience_ids?: string[];
  min_polygon_interaction?: number;
  min_score?: number;
  sort: string;
  campaign?: null | GetCampaignResponse;
}

export interface AudienceGroupRequest extends AudienceRequest {
  group_by?: GroupBy;
}

export type AudienceRenderMode = 'infinite' | 'grouped_by_last_heard' | 'grouped_by_polygon';

export interface FilterSource {
  [key: string]: {
    displayName: string;
    logo: string;
    default: boolean;
  };
}

export enum BasemapLayerOptions {
  mapboxLight = 'mapbox://styles/mapbox/light-v11',
  mapboxDark = 'mapbox://styles/mapbox/dark-v11',
  mapboxStreets = 'mapbox://styles/mapbox/streets-v12',
  mapboxOutdoors = 'mapbox://styles/mapbox/outdoors-v12',
  mapboxNavDay = 'mapbox://styles/mapbox/navigation-day-v1',
  mapboxNavNight = 'mapbox://styles/mapbox/navigation-night-v1',
  osm = 'osm'
}

export interface AudienceDetailPanelDateRange {
  minDate: Dayjs;
  maxDate: Dayjs;
  // epoch:
  minEpoch: number;
  maxEpoch: number;
  // human friendly:
  minDateStr: string;
  maxDateStr: string;

  singleDay: boolean;
}

export enum AudienceDetailPanelChartType {
  dwell = 'Dwell',
  activity = 'Activity',
  scores = 'Scores'
}

export interface AuddienceDetailPanelChartOptions {
  label: AudienceDetailPanelChartType;
  color: string;
}

export interface EntityNote {
  id: string;
  note: string;
  created_at: string;
  created_by: string;
}
