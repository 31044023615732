import { load, Root } from 'protobufjs';
const { VITE_DISABLE_PBF } = import.meta.env;

import protodefs from './assets/proto.pbf';

export type Exports = {
  root: undefined | Root;
  loading: boolean;
};

const exports: Exports = {
  root: undefined,
  loading: VITE_DISABLE_PBF ? false : true
};

if (!VITE_DISABLE_PBF) {
  load(protodefs, (e, root) => {
    exports.loading = false;
    if (e) {
      console.error(e);
      return;
    }
    exports.root = root;
  });
}

export default exports;
