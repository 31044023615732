import { PaletteMode } from '@mui/material';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { LngLatBoundsLike } from 'react-map-gl';

import { GadmL0ResponseRow } from '@stats/types';
import { RootState } from './store';
import {
  Audience,
  BasemapLayerOptions,
  CampaignRawAggDimensions,
  EGEDataRequestFilters,
  GetAudienceSort,
  GetCampaignResponse,
  GetLocationSort,
  GetLocationsResponse,
  MapViewport,
  User
} from './types';
import { mapBoundsWKT } from './utils';

export interface SliceState {
  user: User;
  campaign?: GetCampaignResponse | null;
  campaignRawAggDimension: CampaignRawAggDimensions;
  campaignRawAggSize: number;
  campaignRawDateRange?: [number, number];

  campaignClusterLayerEnabled: boolean;

  // Map State
  initialMapViewport: MapViewport;
  delayedMapViewport?: MapViewport;
  delayedMapBounds?: [[number, number], [number, number]];
  delayedMapBoundsWKT?: string;
  delayedMapBoundsBox?: {
    northEast: { lat: number; lng: number };
    southWest: { lat: number; lng: number };
  };

  mapLayerType: BasemapLayerOptions;

  // Map Actions
  fitBounds?: null | LngLatBoundsLike;

  // UI Layout
  themeMode: PaletteMode | 'system';
  campaignCreatorOpen: boolean;

  // Data Layer Toggles
  isEgeDataOn: boolean;
  isPolygonDataOn: boolean;
  egeFetchCounter: number;

  // Searching
  searchBarFilter: string;

  // Locations
  detailsLocation?: GetLocationsResponse;
  selectedLocations: GetLocationsResponse[];
  selectedLocationIds: string[];
  selectedLocationsLookup: Record<string, GetLocationsResponse>;
  sortLocationsBy: GetLocationSort;
  locationFilterCountry: GadmL0ResponseRow[];
  locationFilterCategory: string[];
  locationFilterMinUniqueEntities: number;
  locationFilterAdvertiserId: string;
  locationShowOnlySelections: boolean;
  isLocationNetworkEnabled: boolean;
  locationNetworkPolygonId?: string;

  // Audience
  detailsAudience?: Audience;
  detailsAudienceDateRange?: [number, number];
  selectedAudience: Audience[];
  selectedAudienceIds: string[];
  selectedAudienceLookup: Record<string, Audience>;
  selectedAudienceColorOverride: Record<string, string>;
  sortAudienceBy: GetAudienceSort;
  audienceFilterMinPolygons: number;
  audienceFilterMinScore: number;
  audienceShowOnlySelections: boolean;
}

const initialState: SliceState = {
  user: { user: '' },
  themeMode: 'system',
  campaignRawAggDimension: 'count',
  campaignRawAggSize: 1,
  campaignClusterLayerEnabled: false,

  // the map will load the viewport from `delayedMapViewport` if it is set,
  // otherwise it will load from `initialMapViewport`:
  initialMapViewport: {
    latitude: 30,
    longitude: 0,
    zoom: 2
  },
  isEgeDataOn: true,
  isPolygonDataOn: true,
  mapLayerType: BasemapLayerOptions.mapboxLight,
  egeFetchCounter: 0,

  searchBarFilter: '',

  detailsLocation: undefined,
  detailsAudience: undefined,
  detailsAudienceDateRange: undefined,
  campaignCreatorOpen: false,

  selectedLocations: [],
  selectedLocationIds: [],
  selectedLocationsLookup: {},
  sortLocationsBy: 'unique_visitors',
  locationFilterCountry: [],
  locationFilterCategory: [],
  locationFilterMinUniqueEntities: 0,
  locationFilterAdvertiserId: '',
  locationShowOnlySelections: false,
  isLocationNetworkEnabled: false,

  selectedAudience: [],
  selectedAudienceIds: [],
  selectedAudienceLookup: {},
  selectedAudienceColorOverride: {},
  sortAudienceBy: 'score',
  audienceFilterMinPolygons: 0,
  audienceFilterMinScore: 0,
  audienceShowOnlySelections: false
};

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    setCampaignRawDateRange: (state, action: PayloadAction<[number, number]>) => {
      state.campaignRawDateRange = action.payload;
    },
    setCampaignRawAggSize: (state, action: PayloadAction<number>) => {
      state.campaignRawAggSize = action.payload;
    },
    setCampaignRawAggDimension: (state, action: PayloadAction<CampaignRawAggDimensions>) => {
      state.campaignRawAggDimension = action.payload;
    },
    setCampaign: (state, action: PayloadAction<undefined | null | GetCampaignResponse>) => {
      state.campaign = action.payload;
      if (action.payload?.bounds) {
        state.fitBounds = action.payload.bounds;
      }
      if (!action.payload) {
        // clear all selections
        state.selectedAudience = [];
        state.selectedAudienceIds = [];
        state.selectedAudienceLookup = {};
        state.selectedLocationIds = [];
        state.selectedLocations = [];
        state.selectedLocationsLookup = {};
        state.detailsAudience = undefined;
        state.detailsLocation = undefined;
        state.selectedAudienceColorOverride = {};
        state.locationShowOnlySelections = false;
        state.audienceShowOnlySelections = false;
        state.detailsAudienceDateRange = undefined;
      }
    },
    toggleCampaignCreator: (state) => {
      state.campaignCreatorOpen = !state.campaignCreatorOpen;
    },
    renderEGE: (state) => {
      state.egeFetchCounter += 1;
      state.isEgeDataOn = true;
    },
    toggleShowOnlySelectedLocations: (state) => {
      state.locationShowOnlySelections = !state.locationShowOnlySelections;
    },
    toggleShowOnlySelectedAudience: (state) => {
      state.audienceShowOnlySelections = !state.audienceShowOnlySelections;
    },
    setAudienceMinPolygons: (state, action: PayloadAction<number>) => {
      state.audienceFilterMinPolygons = action.payload;
    },
    setAudienceMinScore: (state, action: PayloadAction<number>) => {
      state.audienceFilterMinScore = action.payload;
    },
    commitAudienceFilters: (
      state,
      action: PayloadAction<{
        minPolygons: number;
        minScore: number;
      }>
    ) => {
      state.audienceFilterMinPolygons = action.payload.minPolygons;
      state.audienceFilterMinScore = action.payload.minScore;
    },
    clearLocationFilterCountry: (state) => {
      state.locationFilterCountry = [];
    },
    clearLocationFilterCategory: (state) => {
      state.locationFilterCategory = [];
    },
    addLocationFilterCountry: (state, action: PayloadAction<GadmL0ResponseRow[]>) => {
      const existingISO3 = state.locationFilterCountry.map((c) => c.iso3);
      const newCountries = action.payload.filter((c) => !existingISO3.includes(c.iso3));
      state.locationFilterCountry = [...state.locationFilterCountry, ...newCountries];
    },
    addLocationFilterCategory: (state, action: PayloadAction<string[]>) => {
      state.locationFilterCategory = [...new Set([...state.locationFilterCategory, ...action.payload])];
    },
    toggleLocationFilterCountry: (state, action: PayloadAction<GadmL0ResponseRow>) => {
      const idx = state.locationFilterCountry.findIndex((c) => c.iso3 === action.payload.iso3);
      if (idx === -1) {
        state.locationFilterCountry.push(action.payload);
      } else {
        state.locationFilterCountry.splice(idx, 1);
      }
    },
    toggleLocationFilterCategory: (state, action: PayloadAction<string>) => {
      const idx = state.locationFilterCategory.indexOf(action.payload);
      if (idx === -1) {
        state.locationFilterCategory.push(action.payload);
      } else {
        state.locationFilterCategory.splice(idx, 1);
      }
    },
    commitLocationFilters: (
      state,
      action: PayloadAction<{
        category: string[];
        country: GadmL0ResponseRow[];
        minUniqueEntities: number;
        advertiserId: string;
      }>
    ) => {
      state.locationFilterCategory = action.payload.category;
      state.locationFilterCountry = action.payload.country;
      state.locationFilterMinUniqueEntities = action.payload.minUniqueEntities;
      state.locationFilterAdvertiserId = action.payload.advertiserId;
    },
    setLocationFilterMinUniqueEntities: (state, action: PayloadAction<number>) => {
      state.locationFilterMinUniqueEntities = action.payload;
    },
    setLocationFilterAdvertiserId: (state, action: PayloadAction<string>) => {
      state.locationFilterAdvertiserId = action.payload;
    },
    setLocationSort: (state, action: PayloadAction<GetLocationSort>) => {
      state.sortLocationsBy = action.payload;
    },
    setAudienceSort: (state, action: PayloadAction<GetAudienceSort>) => {
      state.sortAudienceBy = action.payload;
    },
    setAudienceDetailsDateRange: (state, action: PayloadAction<[number, number]>) => {
      state.detailsAudienceDateRange = action.payload;
    },
    clearAudienceDetailsDateRange: (state) => {
      state.detailsAudienceDateRange = undefined;
    },
    setAudienceDetails: (state, action: PayloadAction<Audience>) => {
      state.detailsAudience = action.payload;
      state.detailsLocation = undefined;
    },
    clearAudienceDetails: (state) => {
      state.detailsAudience = undefined;
      state.detailsLocation = undefined;
    },
    setAudienceColor: (state, action: PayloadAction<{ advertiser_id: string; color: string }>) => {
      state.selectedAudienceColorOverride[action.payload.advertiser_id] = action.payload.color;
    },
    setLocationDetails: (state, action: PayloadAction<GetLocationsResponse>) => {
      state.detailsLocation = action.payload;
      state.detailsAudience = undefined;
    },
    clearLocationDetails: (state) => {
      state.detailsLocation = undefined;
      state.detailsAudience = undefined;
    },
    replaceAudience: (state, action: PayloadAction<Audience>) => {
      state.selectedAudience = [action.payload];
      state.selectedAudienceIds = [action.payload.advertiser_id];
      state.selectedAudienceLookup = { [action.payload.advertiser_id]: action.payload };
      // when a new audience is toggled "on", also enable its detail panel:
      state.detailsLocation = undefined;
      state.detailsAudience = action.payload;
      if (state.selectedAudienceIds.length === 0) state.audienceShowOnlySelections = false;
    },
    toggleAudience: (state, action: PayloadAction<Audience>) => {
      if (state.selectedAudienceIds.includes(action.payload.advertiser_id)) {
        state.selectedAudienceIds = state.selectedAudienceIds.filter((id) => id !== action.payload.advertiser_id);
        state.selectedAudience = state.selectedAudience.filter((a) => a.advertiser_id !== action.payload.advertiser_id);
        delete state.selectedAudienceLookup[action.payload.advertiser_id];
      } else {
        state.selectedAudience.push(action.payload);
        state.selectedAudienceIds.push(action.payload.advertiser_id);
        state.selectedAudienceLookup[action.payload.advertiser_id] = action.payload;
        // when a new audience is toggled "on", also enable its detail panel:
        state.detailsLocation = undefined;
        state.detailsAudience = action.payload;
      }
      if (state.selectedAudienceIds.length === 0) state.audienceShowOnlySelections = false;
    },
    addAudience: (state, action: PayloadAction<Audience[]>) => {
      for (const loc of action.payload) {
        if (!state.selectedAudienceLookup[loc.advertiser_id]) {
          state.selectedAudience.push(loc);
          state.selectedAudienceLookup[loc.advertiser_id] = loc;
          state.selectedAudienceIds.push(loc.advertiser_id);
        }
      }
      if (state.selectedAudienceIds.length === 0) state.audienceShowOnlySelections = false;
    },
    addLocations: (state, action: PayloadAction<GetLocationsResponse[]>) => {
      for (const loc of action.payload) {
        if (!state.selectedLocationsLookup[loc.polygon_id]) {
          state.selectedLocations.push(loc);
          state.selectedLocationsLookup[loc.polygon_id] = loc;
          state.selectedLocationIds.push(loc.polygon_id);
        }
      }
      if (state.selectedLocationIds.length === 0) state.locationShowOnlySelections = false;
    },
    replaceLocation: (state, action: PayloadAction<GetLocationsResponse>) => {
      state.selectedLocations = [action.payload];
      state.selectedLocationIds = [action.payload.polygon_id];
      state.selectedLocationsLookup = { [action.payload.polygon_id]: action.payload };
      // when a new location is toggled "on", also enable its detail panel:
      state.detailsAudience = undefined;
      state.detailsLocation = action.payload;
      if (state.selectedLocationIds.length === 0) state.locationShowOnlySelections = false;
    },
    // Silent toggleLocation does not enable the detail panel
    toggleLocationSilent: (state, action: PayloadAction<GetLocationsResponse>) => {
      if (state.selectedLocationIds.includes(action.payload.polygon_id)) {
        state.selectedLocationIds = state.selectedLocationIds.filter((id) => id !== action.payload.polygon_id);
        state.selectedLocations = state.selectedLocations.filter((l) => l.polygon_id !== action.payload.polygon_id);
        delete state.selectedLocationsLookup[action.payload.polygon_id];
      } else {
        state.selectedLocations.push(action.payload);
        state.selectedLocationIds.push(action.payload.polygon_id);
        state.selectedLocationsLookup[action.payload.polygon_id] = action.payload;
      }

      if (state.selectedLocationIds.length === 0) {
        state.selectedAudience = [];
        state.selectedAudienceIds = [];
        state.selectedAudienceLookup = {};
        state.detailsAudience = undefined;
        state.detailsAudienceDateRange = undefined;
        state.audienceShowOnlySelections = false;
        state.locationShowOnlySelections = false;
      }
    },
    toggleLocation: (state, action: PayloadAction<GetLocationsResponse>) => {
      if (state.selectedLocationIds.includes(action.payload.polygon_id)) {
        state.selectedLocationIds = state.selectedLocationIds.filter((id) => id !== action.payload.polygon_id);
        state.selectedLocations = state.selectedLocations.filter((l) => l.polygon_id !== action.payload.polygon_id);
        delete state.selectedLocationsLookup[action.payload.polygon_id];
      } else {
        state.selectedLocations.push(action.payload);
        state.selectedLocationIds.push(action.payload.polygon_id);
        state.selectedLocationsLookup[action.payload.polygon_id] = action.payload;
        // when a new location is toggled "on", also enable its detail panel:
        state.detailsAudience = undefined;
        state.detailsLocation = action.payload;
      }

      if (state.selectedLocationIds.length === 0) {
        state.selectedAudience = [];
        state.selectedAudienceIds = [];
        state.selectedAudienceLookup = {};
        state.detailsAudience = undefined;
        state.detailsAudienceDateRange = undefined;
        state.audienceShowOnlySelections = false;
        state.locationShowOnlySelections = false;
      }
    },
    toggleLocationNetwork: (state) => {
      state.isLocationNetworkEnabled = !state.isLocationNetworkEnabled;
    },
    setLocationNetworkPolygonId: (state, action: PayloadAction<string | undefined>) => {
      state.locationNetworkPolygonId = action.payload;
      state.isLocationNetworkEnabled = true;
    },
    clearAllSelections: (state) => {
      state.isEgeDataOn = true;
      state.isPolygonDataOn = true;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setDelayedMapViewport: (state, action: PayloadAction<{ viewport: MapViewport; bounds: [[number, number], [number, number]] }>) => {
      state.delayedMapViewport = action.payload.viewport;
      state.delayedMapBounds = action.payload.bounds;

      const [southWest, northEast] = action.payload.bounds;
      const delayedMapBoundsBox = {
        northEast: {
          lat: northEast[1],
          lng: northEast[0]
        },
        southWest: {
          lat: southWest[1],
          lng: southWest[0]
        }
      };
      state.delayedMapBoundsBox = delayedMapBoundsBox;
      state.delayedMapBoundsWKT = mapBoundsWKT(delayedMapBoundsBox);

      // automatically shift the h3 size based on zoom level,
      state.campaignRawAggSize = Math.min(6, Math.floor(action.payload.viewport.zoom) + 1);
    },
    fitBoundsOnMap: (state, action: PayloadAction<LngLatBoundsLike>) => {
      state.fitBounds = action.payload;
    },
    fitBoundsComplete: (state) => {
      state.fitBounds = undefined;
    },
    setThemeMode: (state, action: PayloadAction<PaletteMode>) => {
      state.themeMode = action.payload;
    },
    restoreState: (state, action: PayloadAction<SliceState>) => {
      // we cannot override the entire `state` object because its
      // just a reference in this function... so we have to override
      // each property individually:
      state.themeMode = action.payload.themeMode;
      state.delayedMapViewport = action.payload.delayedMapViewport;

      // do not load user, it needs to come from auth provider.
      // state.user = action.payload.user;
      // do not load fitBounds, don't drive the map when restoring state.
      // state.fitBounds = action.payload.fitBounds;
      // do not load intiialMapViewPort, it should be the same for all users.
      // state.initialMapViewport = action.payload.initialMapViewport;
    },
    toggleEgeData: (state) => {
      state.isEgeDataOn = !state.isEgeDataOn;

      if (state.isEgeDataOn) {
        state.egeFetchCounter += 1;
      }
    },
    togglePolygonData: (state) => {
      state.isPolygonDataOn = !state.isPolygonDataOn;
    },
    setMapLayerType: (state, action: PayloadAction<BasemapLayerOptions>) => {
      state.mapLayerType = action.payload;
    },
    setSearchBarFilter: (state, action: PayloadAction<string>) => {
      state.searchBarFilter = action.payload;
    },
    toggleCampaignClusterLayer: (state, action: PayloadAction<boolean | undefined>) => {
      // if action.payload is set, use it, otherwise toggle the current state:
      if (action?.payload !== undefined) {
        state.campaignClusterLayerEnabled = action.payload;
      } else {
        state.campaignClusterLayerEnabled = !state.campaignClusterLayerEnabled;
      }
    }
  }
});

const selectAudienceFilterMinPolygons = (store: RootState) => store.slice.audienceFilterMinPolygons;
const selectAudienceFilterMinScore = (store: RootState) => store.slice.audienceFilterMinScore;
const selectAudienceShowOnlySelections = (store: RootState) => store.slice.audienceShowOnlySelections;
const selectCampaignRawAggDimension = (store: RootState) => store.slice.campaignRawAggDimension;
const selectCampaignRawDateRange = (store: RootState) => store.slice.campaignRawDateRange;
const selectCampaignRawAggSize = (store: RootState) => store.slice.campaignRawAggSize;
const selectDelayedMapBounds = (store: RootState) => store.slice.delayedMapBounds;
const selectDelayedMapBoundsBox = (store: RootState) => store.slice.delayedMapBoundsBox;
const selectDelayedMapBoundsWKT = (store: RootState) => store.slice.delayedMapBoundsWKT;
const selectDelayedMapViewport = (store: RootState) => store.slice.delayedMapViewport;
const selectDetailsAudienceDateRange = (store: RootState) => store.slice.detailsAudienceDateRange;
const selectEgeFetchCounter = (store: RootState) => store.slice.egeFetchCounter;
const selectFitBounds = (store: RootState) => store.slice.fitBounds;
const selectInitialMapViewport = (store: RootState) => store.slice.initialMapViewport;
const selectIsEgeDataOn = (store: RootState) => store.slice.isEgeDataOn;
const selectIsPolygonDataOn = (store: RootState) => store.slice.isPolygonDataOn;
const selectLocationFilterAdvertiserId = (store: RootState) => store.slice.locationFilterAdvertiserId;
const selectLocationFilterCategory = (store: RootState) => store.slice.locationFilterCategory;
const selectLocationFilterCountry = (store: RootState) => store.slice.locationFilterCountry;
const selectLocationFilterMinUniqueEntities = (store: RootState) => store.slice.locationFilterMinUniqueEntities;
const selectIsLocationNetworkEnabled = (store: RootState) => store.slice.isLocationNetworkEnabled;
const selectLocationNetworkPolygonId = (store: RootState) => store.slice.locationNetworkPolygonId;
const selectLocationShowOnlySelections = (store: RootState) => store.slice.locationShowOnlySelections;
const selectMapLayerType = (store: RootState) => store.slice.mapLayerType;
const selectSearchBarFilter = (store: RootState) => store.slice.searchBarFilter;
const selectSelectedAudience = (store: RootState) => store.slice.selectedAudience;
const selectSelectedAudienceColorOverride = (state: RootState) => state.slice.selectedAudienceColorOverride;
const selectSelectedAudienceIds = (store: RootState) => store.slice.selectedAudienceIds;
const selectSelectedLocationIds = (store: RootState) => store.slice.selectedLocationIds;
const selectSelectedLocations = (store: RootState) => store.slice.selectedLocations;
const selectSlice = (store: RootState) => JSON.stringify(store.slice);
const selectSortAudienceBy = (store: RootState) => store.slice.sortAudienceBy;
const selectSortLocationsBy = (store: RootState) => store.slice.sortLocationsBy;
const selectUser = (store: RootState) => store.slice.user;
const selectCampaignCreatorOpen = (store: RootState) => store.slice.campaignCreatorOpen;
const selectCampaign = (store: RootState) => store.slice.campaign;
const selectCampaignClusterLayerEnabled = (store: RootState) => store.slice.campaignClusterLayerEnabled;
const selectDetailsAudience = (store: RootState) => store.slice.detailsAudience;
const selectLocationDetails = (store: RootState) => store.slice.detailsLocation;

const alertsModelSelector = createSelector([selectUser], (user) => ({
  user
}));

// Used by the Map Component:
const mapComponentSelector = createSelector(
  [selectFitBounds, selectInitialMapViewport, selectDelayedMapViewport, selectMapLayerType, selectCampaign, selectCampaignRawAggDimension],
  (
    // \n
    fitBounds,
    initialMapViewport,
    delayedMapViewport,
    mapLayerType,
    campaign,
    campaignRawAggDimension
  ) => ({
    fitBounds,
    initialMapViewport,
    delayedMapViewport,
    mapLayerType,
    campaign,
    campaignRawAggDimension
  })
);
// Used by AppView.tsx
const appComponentSelector = createSelector([selectUser, selectDelayedMapBounds, selectCampaign], (user, delayedMapBounds, campaign) => ({
  user,
  delayedMapBounds,
  campaign
}));

const audienceV2ListSelector = createSelector([selectSelectedLocationIds, selectSelectedAudienceColorOverride], (selectedLocationIds, selectedAudienceColorOverride) => ({
  selectedLocationIds,
  selectedAudienceColorOverride
}));

const buttonBarSelector = createSelector([selectIsEgeDataOn, selectIsPolygonDataOn], (isEgeDataOn, isPolygonDataOn) => ({
  isEgeDataOn,
  isPolygonDataOn
}));

const saveStateSelector = createSelector([selectSlice], (slice) => ({
  slice
}));

const panelHeaderSelector = createSelector([selectSearchBarFilter], (searchBarFilter) => ({
  searchBarFilter
}));

// selectedLocationsLookup, selectedAudienceColorOverride
const entityDetailsSelector = createSelector([selectDetailsAudienceDateRange, selectSelectedLocations], (detailsAudienceDateRange, selectedLocations) => ({
  detailsAudienceDateRange,
  selectedLocations
}));

const useGetLocationQueryHookSelector = createSelector(
  [
    selectDelayedMapBounds,
    selectSortLocationsBy,
    selectLocationFilterCategory,
    selectLocationFilterCountry,
    selectLocationFilterMinUniqueEntities,
    selectLocationFilterAdvertiserId,
    selectCampaign
  ],
  (bounding_box, sort, categories, countries, min_unique_entities, advertiser_ids, campaign) => ({
    categories,
    countries,
    bounding_box,
    sort,
    min_unique_entities,
    advertiser_ids,
    campaign
  })
);

const polygonMapLayerSelector = createSelector([selectSelectedLocationIds, selectIsPolygonDataOn], (selectedLocationIds, isPolygonDataOn) => ({
  selectedLocationIds,
  isPolygonDataOn
}));

const useEGESelector = createSelector(
  [selectDelayedMapBoundsBox, selectDelayedMapViewport, selectDelayedMapBoundsWKT, selectEgeFetchCounter, selectIsEgeDataOn],
  (delayedMapBoundsBox, delayedMapViewport, delayedMapBoundsWKT, egeFetchCounter, isEgeDataOn) => ({
    delayedMapBoundsBox,
    delayedMapZoom: delayedMapViewport?.zoom,
    delayedMapBoundsWKT,
    egeFetchCounter,
    isEgeDataOn
  })
);

// provide a stable object for the `useSelector` hook to prevent unnecessary re-renders:
const useEGESelectorFilters = createSelector(
  [selectSelectedAudienceIds],
  (selectedAudienceIds): EGEDataRequestFilters => ({
    selectedAudienceIds
  })
);

const locationFiltersSelector = createSelector(
  [selectLocationFilterCategory, selectLocationFilterCountry, selectLocationFilterMinUniqueEntities, selectLocationFilterAdvertiserId],
  (locationFilterCategory, locationFilterCountry, locationFilterMinUniqueEntities, locationFilterAdvertiserId) => ({
    locationFilterCategory,
    locationFilterCountry,
    locationFilterMinUniqueEntities,
    locationFilterAdvertiserId
  })
);

const locationNetworkSelector = createSelector(
  [selectIsLocationNetworkEnabled, selectLocationNetworkPolygonId],
  (isLocationNetworkEnabled, locationNetworkPolygonId) => ({
    isLocationNetworkEnabled,
    locationNetworkPolygonId
  })
);

const layerSwitcherSelector = createSelector(
  [selectMapLayerType, selectIsLocationNetworkEnabled, selectLocationNetworkPolygonId],
  (mapLayerType, isLocationNetworkEnabled, locationNetworkPolygonId) => ({
    mapLayerType,
    isLocationNetworkEnabled,
    locationNetworkPolygonId
  })
);

const layerSwitcherFlycasterAnalyticLayersSelector = createSelector([selectCampaignClusterLayerEnabled, selectCampaign], (campaignClusterLayerEnabled, campaign) => ({
  campaignClusterLayerEnabled,
  campaign
}));

const audienceV2Selector = createSelector(
  [
    // \n
    selectSortAudienceBy,
    selectSelectedLocationIds,
    selectAudienceFilterMinPolygons,
    selectAudienceFilterMinScore,
    selectAudienceShowOnlySelections,
    selectSelectedAudience,
    selectCampaign
  ],
  (sortAudienceBy, selectedLocationIds, audienceFilterMinPolygons, audienceFilterMinScore, audienceShowOnlySelections, selectedAudience, campaign) => ({
    sortAudienceBy,
    selectedLocationIds,
    audienceFilterMinPolygons,
    audienceFilterMinScore,
    audienceShowOnlySelections,
    selectedAudience,
    campaign
  })
);

const locationV2Selector = createSelector([selectSelectedLocations, selectLocationShowOnlySelections], (selectedLocations, locationShowOnlySelections) => ({
  selectedLocations,
  locationShowOnlySelections
}));

const audienceV2FilterPopoverSelector = createSelector(
  [selectAudienceFilterMinPolygons, selectAudienceFilterMinScore],
  (audienceFilterMinPolygons, audienceFilterMinScore) => ({
    audienceFilterMinPolygons,
    audienceFilterMinScore
  })
);
const ifReceiverSelector = createSelector([selectSelectedAudienceIds, selectSelectedLocationIds], (selectedAudienceIds, selectedLocationIds) => ({
  selectedAudienceIds,
  selectedLocationIds
}));

const campaignCreatorSelector = createSelector(
  [selectCampaignCreatorOpen, selectSelectedAudienceIds, selectSelectedLocationIds],
  (campaignCreatorOpen, selectedAudienceIds, selectedLocationIds) => ({
    campaignCreatorOpen,
    selectedAudienceIds,
    selectedLocationIds
  })
);

const useRawLayerSelector = createSelector(
  [
    selectSelectedAudienceColorOverride,
    selectCampaign,
    selectSelectedAudienceIds,
    selectDelayedMapBoundsWKT,
    selectIsEgeDataOn,
    selectDelayedMapViewport,
    selectCampaignRawAggDimension,
    selectCampaignRawAggSize,
    selectCampaignRawDateRange
  ],
  (
    selectedAudienceColorOverride,
    campaign,
    selectedAudienceIds,
    delayedMapBoundsWKT,
    isEgeDataOn,
    delayedMapViewport,
    campaignRawAggDimension,
    campaignRawAggSize,
    campaignRawDateRange
  ) => ({
    selectedAudienceColorOverride,
    campaign,
    selectedAudienceIds,
    delayedMapBoundsWKT,
    isEgeDataOn,
    delayedMapZoom: delayedMapViewport?.zoom,
    campaignRawAggDimension,
    campaignRawAggSize,
    campaignRawDateRange
  })
);

const useH3ClusterLayerSelector = createSelector(
  [selectCampaign, selectDelayedMapBoundsWKT, selectCampaignClusterLayerEnabled, selectSelectedAudienceIds],
  (campaign, delayedMapBoundsWKT, campaignClusterLayerEnabled, selectedAudienceIds) => ({
    campaign,
    delayedMapBoundsWKT,
    campaignClusterLayerEnabled,
    selectedAudienceIds
  })
);

const audienceV2ShowOnlySelectedButtonSelector = createSelector([selectAudienceShowOnlySelections], (audienceShowOnlySelections) => ({
  audienceShowOnlySelections
}));

const locationV2ShowOnlySelectedButtonSelector = createSelector([selectLocationShowOnlySelections], (locationShowOnlySelections) => ({
  locationShowOnlySelections
}));

const campaignHeaderSelector = createSelector(
  [selectSelectedAudienceIds, selectDelayedMapBoundsWKT, selectIsEgeDataOn, selectDelayedMapViewport],
  (selectedAudienceIds, delayedMapBoundsWKT, isEgeDataOn, delayedMapViewport) => ({
    selectedAudienceIds,
    delayedMapBoundsWKT,
    isEgeDataOn,
    delayedMapZoom: delayedMapViewport?.zoom
  })
);

const audienceV2TimelineChartSelector = createSelector(
  [selectSelectedAudienceIds, selectCampaign, selectCampaignRawDateRange, selectDelayedMapViewport],
  (selectedAudienceIds, campaign, campaignRawDateRange, delayedMapViewport) => ({
    selectedAudienceIds,
    campaign,
    campaignRawDateRange,
    delayedMapZoom: delayedMapViewport?.zoom
  })
);

const audienceV2DetailsSelector = createSelector(
  [selectDetailsAudience, selectSelectedLocationIds, selectSelectedAudienceIds],
  (detailsAudience, selectedLocationIds, selectedAudienceIds) => ({
    detailsAudience,
    selectedLocationIds,
    selectedAudienceIds
  })
);

const locationV2DetailsSelector = createSelector([selectLocationDetails, selectSelectedAudienceIds], (locationDetails, selectedAudienceIds) => ({
  locationDetails,
  selectedAudienceIds
}));

export const sliceSelectors = {
  locationV2DetailsSelector,
  audienceV2DetailsSelector,
  audienceV2TimelineChartSelector,
  campaignHeaderSelector,
  useRawLayerSelector,
  useH3ClusterLayerSelector,
  mapComponentSelector,
  appComponentSelector,
  saveStateSelector,
  buttonBarSelector,
  alertsModelSelector,
  panelHeaderSelector,
  entityDetailsSelector,
  useEGESelector,
  useEGESelectorFilters,
  useGetLocationQueryHookSelector,
  polygonMapLayerSelector,
  audienceV2ListSelector,
  locationFiltersSelector,
  locationNetworkSelector,
  layerSwitcherSelector,
  layerSwitcherFlycasterAnalyticLayersSelector,
  audienceV2Selector,
  locationV2Selector,
  audienceV2FilterPopoverSelector,
  ifReceiverSelector,
  campaignCreatorSelector,
  audienceV2ShowOnlySelectedButtonSelector,
  locationV2ShowOnlySelectedButtonSelector
};

// Action creators are generated for each case reducer function
export const {
  setCampaignRawDateRange,
  setCampaignRawAggSize,
  setUser,
  setThemeMode,
  setDelayedMapViewport,
  fitBoundsOnMap,
  restoreState,
  toggleEgeData,
  togglePolygonData,
  clearAllSelections,
  setMapLayerType,
  toggleLocation,
  toggleLocationNetwork,
  setLocationNetworkPolygonId,
  setLocationDetails,
  clearLocationDetails,
  toggleAudience,
  setAudienceColor,
  setAudienceDetails,
  clearAudienceDetails,
  setAudienceDetailsDateRange,
  clearAudienceDetailsDateRange,
  setSearchBarFilter,
  toggleCampaignClusterLayer,
  replaceAudience,
  replaceLocation,
  toggleLocationSilent,
  setLocationSort,
  toggleLocationFilterCategory,
  toggleLocationFilterCountry,
  setLocationFilterMinUniqueEntities,
  setLocationFilterAdvertiserId,
  clearLocationFilterCategory,
  clearLocationFilterCountry,
  addLocationFilterCategory,
  addLocationFilterCountry,
  commitLocationFilters,
  setAudienceSort,
  setAudienceMinPolygons,
  setAudienceMinScore,
  commitAudienceFilters,
  toggleShowOnlySelectedAudience,
  toggleShowOnlySelectedLocations,
  renderEGE,
  toggleCampaignCreator,
  addLocations,
  addAudience,
  setCampaign,
  fitBoundsComplete,
  setCampaignRawAggDimension
} = slice.actions;

export default slice.reducer;
